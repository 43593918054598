<template>
	<w-dialog max-width="500px" :title="dialogTitle" @close="onClose">
		<w-form-builder v-model="copiedValue" :fields="fields" :validation.sync="isValid" />
		<template v-slot:actions>
			<w-spacer />
			<w-btn :disabled="!isValid" flat @click="onClick">{{ $t('actions.validate') }}</w-btn>
		</template>
	</w-dialog>
</template>

<script>
export default {
	name: 'ModuleParametersDialog',
	props: {
		fields: {
			required: false,
			type: Array,
			default: () => []
		},
		module: {
			required: true,
			type: Object
		},
		value: {
			required: false,
			type: Object,
			default: () => ({})
		}
	},
	data: function () {
		return {
			copiedValue: {},
			isValid: false
		}
	},
	computed: {
		dialogTitle: function () {
			return this.$t('admin_group.manager.modules.module_settings', { name: this.module.title })
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				if (val) {
					this.copiedValue = { ...val }
				} else {
					this.copiedValue = {}
				}
			},
			immediate: true
		}
	},
	methods: {
		onClick: function () {
			this.$emit('input', this.copiedValue)
		},
		onClose: function () {
			this.$emit('close')
		}
	}
}
</script>
