<template>
	<w-layout row wrap>
		<w-flex px-1 xs12 md6>
			<w-layout column>
				<v-subheader v-t="'admin_group.manager.accounting_firms_modules'" />
				<v-list v-if="modulesForAccountingFirm.length > 0" dense>
					<v-list-tile>
						<w-checkbox
							v-model="accountingFirmModulesAssignmentState"
							color="secondary"
							false-value="none"
							:indeterminate="accountingFirmModulesAssignmentState === 'some'"
							:ripple="false"
							style="margin-top: 0"
							true-value="all"
						>
							<template v-slot:label>
								<w-flex v-t="'admin_group.manager.select_all_accounting_firm_modules'" shrink />
							</template>
						</w-checkbox>
					</v-list-tile>
					<v-divider />
					<v-list-tile v-for="item in modulesForAccountingFirm" :key="item.id">
						<w-checkbox :ripple="false" style="margin-top: 0" :value="usedModules.includes(item.id)" @input="updateModuleAssignment(item, $event)">
							<template v-slot:label>
								<w-flex v-if="item.accounting_firm_parameters.length > 0 && usedModules.includes(item.id)" shrink style="margin-right: 5px">
									<v-tooltip left>
										<template v-slot:activator="{ on }">
											<span v-on="on" @click.stop="updateModuleParameters(item)">
												<span class="fas fa-fw fa-pencil-alt"></span>
											</span>
										</template>
										<span>Éditer les paramètres personnalisés</span>
									</v-tooltip>
								</w-flex>
								<w-flex shrink v-text="item.title" />
							</template>
						</w-checkbox>
					</v-list-tile>
				</v-list>
			</w-layout>
		</w-flex>
		<w-flex px-1 xs12 md6>
			<w-layout column>
				<v-subheader v-t="'admin_group.manager.company_modules'" />
				<v-list v-if="modulesForCompany.length > 0" dense>
					<v-list-tile>
						<w-checkbox
							v-model="companyModulesAssignmentState"
							color="secondary"
							false-value="none"
							:indeterminate="companyModulesAssignmentState === 'some'"
							:ripple="false"
							style="margin-top: 0"
							true-value="all"
						>
							<template v-slot:label>
								<w-flex v-t="'admin_group.manager.select_all_company_modules'" shrink />
							</template>
						</w-checkbox>
					</v-list-tile>
					<v-divider />
					<v-list-tile v-for="item in modulesForCompany" :key="item.id">
						<w-checkbox color="primary" :ripple="false" :value="usedModules.includes(item.id)" @input="updateModuleAssignment(item, $event)">
							<template v-slot:label>
								<w-flex v-if="item.accounting_firm_parameters.length > 0 && usedModules.includes(item.id)" shrink style="margin-right: 5px">
									<v-tooltip left>
										<template v-slot:activator="{ on }">
											<span v-on="on" @click.stop="updateModuleParameters(item)">
												<span class="fas fa-fw fa-pencil-alt"></span>
											</span>
										</template>
										<span>Éditer les paramètres personnalisés</span>
									</v-tooltip>
								</w-flex>
								<w-flex shrink v-text="item.title" />
							</template>
						</w-checkbox>
					</v-list-tile>
				</v-list>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import Vue from 'vue'
import GroupModuleGuard from '@/mixins/ModulesGuards/Group/GroupModuleGuard'
import ModuleParametersDialog from '@/components/Group/Manager/ModuleParametersDialog'

export default {
	name: 'ModuleForm',
	mixins: [GroupModuleGuard],
	props: {
		value: {
			required: false,
			type: Array,
			default: () => []
		}
	},
	data: function () {
		return {
			modulesForAccountingFirm: [],
			modulesForCompany: [],
			usedModules: []
		}
	},
	computed: {
		accountingFirmModulesAssignmentState: {
			get: function () {
				const moduleIds = this.modulesForAccountingFirm.map(m => m.id)

				let nbOfModulesInCommon = 0
				this.value.forEach(m => {
					if (moduleIds.includes(m.id)) {
						nbOfModulesInCommon++
					}
				})

				if (nbOfModulesInCommon === 0) {
					return 'none'
				} else if (nbOfModulesInCommon < moduleIds.length) {
					return 'some'
				} else {
					return 'all'
				}
			},
			set: function (val) {
				let toggleValue
				if (val === 'all') {
					toggleValue = true
				} else {
					toggleValue = false
				}
				this.updateAccountingFirmModulesAssignments(toggleValue)
			}
		},
		companyModulesAssignmentState: {
			get: function () {
				const moduleIds = this.modulesForCompany.map(m => m.id)
				const nbOfModulesInCommon = this.value.filter(m => moduleIds.includes(m.id)).length
				if (nbOfModulesInCommon === 0) {
					return 'none'
				} else if (nbOfModulesInCommon < moduleIds.length) {
					return 'some'
				} else {
					return 'all'
				}
			},
			set: function (val) {
				let toggleValue
				if (val === 'all') {
					toggleValue = true
				} else {
					toggleValue = false
				}
				this.updateCompanyModulesAssignments(toggleValue)
			}
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				if (Array.isArray(val)) {
					this.usedModules = val.map(m => m.id)
				} else {
					this.usedModules = []
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.listModules()
	},
	methods: {
		listModules: function () {
			return this.service.getModules().then(modules => {
				modules.forEach(m => {
					if (m.is_for_accounting_firms) {
						this.modulesForAccountingFirm.push(m)
					} else if (m.is_for_companies) {
						this.modulesForCompany.push(m)
					}
				})
				return modules
			})
		},
		openModuleParametersDialog: function (mod) {
			let fields = mod.accounting_firm_parameters.map(field => {
				field.label = this.$t(`admin_group.manager.modules.${field.name}`)
				return field
			})
			let value = {}
			const accountingFirmModule = this.value.find(m => m.id === mod.id)
			if (accountingFirmModule?.subscription?.parameters) {
				value = JSON.parse(accountingFirmModule.subscription.parameters)
			}

			return new Promise((res, rej) => {
				const dialogInstance = Vue.extend(ModuleParametersDialog)
				const dialogComponent = new dialogInstance({
					propsData: {
						fields: fields,
						module: mod,
						value: value
					},
					parent: this
				}).$mount()

				document.getElementById('app').appendChild(dialogComponent.$el)

				dialogComponent.$on('close', function () {
					document.getElementById('app').removeChild(dialogComponent.$el)
					dialogComponent.$destroy()
					let err = new Error('Cancel')
					rej(err)
				})

				dialogComponent.$on('input', function (event) {
					document.getElementById('app').removeChild(dialogComponent.$el)
					dialogComponent.$destroy()
					res(event)
				})
			})
		},
		updateAccountingFirmModulesAssignments: async function (toggleValue) {
			const usedModules = this.usedModules
			this.modulesForAccountingFirm.asyncForEach(async accountingFirmModule => {
				if ((toggleValue && !usedModules.includes(accountingFirmModule.id)) || (!toggleValue && usedModules.includes(accountingFirmModule.id))) {
					await this.updateModuleAssignment(accountingFirmModule, toggleValue)
				}
			})
		},
		updateCompanyModulesAssignments: async function (toggleValue) {
			const usedModules = this.usedModules
			this.modulesForCompany.asyncForEach(async companyModule => {
				if ((toggleValue && !usedModules.includes(companyModule.id)) || (!toggleValue && usedModules.includes(companyModule.id))) {
					await this.updateModuleAssignment(companyModule, toggleValue)
				}
			})
		},
		updateModuleAssignment: async function (item, toggleValue) {
			let parameters = null
			if (toggleValue && item.accounting_firm_parameters.length > 0) {
				try {
					parameters = await this.openModuleParametersDialog(item)
				} catch (e) {
					return
				}
			}
			const m = await this.service.updateModuleAssignment(item.id, toggleValue, parameters)
			let copiedValue = [...this.value]
			const index = copiedValue.findIndex(mod => mod.id === m.id)
			if (toggleValue && index == -1) {
				copiedValue.push(m)
			} else if (toggleValue) {
				copiedValue = copiedValue.map(mod => {
					if (mod.id === m.id) {
						return m
					}
					return mod
				})
			} else if (!toggleValue && index > -1) {
				copiedValue.splice(index, 1)
			}
			this.$emit('input', copiedValue)
		},
		updateModuleParameters: function (item) {
			this.updateModuleAssignment(item, true)
		}
	}
}
</script>
